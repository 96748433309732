*{
  font-family: 'Noto Sans', sans-serif !important;
}

body, html{
  margin: 0;
  padding: 0;
  touch-action: pan-x pan-y;
  background-color: #FFFFFF;
}
a{
  text-decoration: none;
}
p{
  margin: 0;
  padding: 0;
}

table,
thead,
tbody,
tfoot,
tr,
th,
td {
    width: auto;
    height: auto;
    margin: 0;
    padding: 0;
    border: none;
    border-collapse: inherit;
    border-spacing: 0;
    border-color: inherit;
    text-align: left;
    font-weight: inherit;
    -webkit-border-horizontal-spacing: 0;
    -webkit-border-vertical-spacing: 0;
}

button, input, textarea, select{
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  text-align: inherit;
  outline: none;
}
input::-moz-focus-inner, button::-moz-focus-inner {
  border: 0;
  padding: 0;
}
input:focus:not(:focus-visible), button:focus:not(:focus-visible) {
  outline: none;
}
input:focus:not(:-moz-focusring), button:focus:not(:-moz-focusring) {
  outline: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #0A7EFB70;
  border: 0px none #ffffff;
  border-radius: 100px;
}
::-webkit-scrollbar-thumb:hover {
  background: #0A7EFB50;
}
::-webkit-scrollbar-thumb:active {
  background: #0A7EFB30;
}
::-webkit-scrollbar-track {
  background: #ffffff;
  border: 0px none #ffffff;
  border-radius: 100px;
}
::-webkit-scrollbar-track:hover {
  background: #ffffff;
}
::-webkit-scrollbar-track:active {
  background: #ffffff;
}
::-webkit-scrollbar-corner {
  background: transparent;
}

.modalbottom-enter {
  transform: translateY(100%);
}
.modalbottom-enter-active {
  transform: translateY(0);
  transition: 500ms all ease;
}
.modalbottom-exit {
  transform: translateY(0);
  transition: 500ms all ease;
}
.modalbottom-exit-active {
  transform: translateY(100%);
  transition: 500ms all ease;
}

.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: 500ms all ease;
}
.fade-exit {
  opacity: 1;
  transition: 500ms all ease;
}
.fade-exit-active {
  opacity: 0;
  transition: 500ms all ease;
}
